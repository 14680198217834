/*******************************
    User Variable Overrides
*******************************/

.ui.cards > .card > .content .meta + .description,
.ui.cards > .card > .content .header + .description,
.ui.cards > .card > .content .description + .description,
.ui.card > .content .meta + .description,
.ui.card > .content .header + .description,
.ui.card > .content .description + .description {
  margin-top: @descriptionDistance;
}

.ui.cards > .card > .content > .description,
.ui.card > .content > .description {
  color: #0E1F40;
}

.ui.cards > .card > .content:not(:first-of-type) > .header:not(.ui),
.ui.card > .content:not(:first-of-type) > .header:not(.ui) {
  color: #94A2B4;
  margin-bottom: 0.8em;
  font-weight: 600;
}

@media (max-width: 768px) {
  .ui.cards > .card,
  .ui.card {
    border: 0 !important;
    border-radius: 0 !important;
    border-bottom: 1px solid @lightGrey !important;
    margin: 0 (@horizontalSpacing / 2) !important;

    &:last-child {
      border-bottom: 0 !important;
    }
  }

  .ui.cards > .card > .content,
  .ui.card > .content {
    padding-right: 0;
    padding-left: 0;
  }

  .ui.cards > .card > .content > .header:not(.ui),
  .ui.card > .content > .header:not(.ui) {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .ui.cards > .card > .content > .header:not(.ui),
  .ui.card > .content > .header:not(.ui) {
    font-size: 16px;
  }
}
