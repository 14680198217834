/*******************************
         Site Overrides
*******************************/

.ui.button {
  font-weight: 600 !important;
  font-family: BuenosAires, sans-serif !important;
  
  &:last-of-type {
    margin-right: 0;
  }
}

.ui.compact.button {
  padding: 7px 0.8em;
}

.ui.button.attached {
  box-shadow: none !important;
}

.ui.right.button.attached {
  box-shadow: -1px 0 1px -1px rgb(34 36 38) !important;
}
