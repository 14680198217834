/*******************************
         Site Overrides
*******************************/

.ui.modal > .header:not(.ui) {
  text-align: center;
}

.ui.modal .scrolling.content {
  max-height: calc(95vh - 10em);
}
