/*******************************
         Site Overrides
*******************************/

.ui.checkbox input.hidden + label {
  line-height: 24px;
}

.ui.toggle.checkbox label:before {
  width: 48px;
  height: 24px;
  background: #fff !important;
  border: 1px solid var(--medzy-color-salt) !important;
}

.ui.toggle.checkbox input:focus:checked ~ label:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background: var(--medzy-color-accent) !important;
  border: 1px solid var(--medzy-color-accent) !important;
}

.ui.disabled.checkbox label,
.ui.checkbox input[disabled] ~ label {
  opacity: 1;
}

.ui.disabled.checkbox input:checked ~ .box:after,
.ui.disabled.checkbox input:checked ~ label:after {
  color: var(--medzy-color-salt) !important;
}

.ui.disabled.checkbox label:before {
  border: 1px solid var(--medzy-color-salt) !important;
  background: var(--medzy-color-neutral) !important;
}

.ui.toggle.checkbox input ~ label:after {
  width: 16px;
  height: 16px;
  box-shadow: none;
  background: var(--medzy-color-neutral);
  border: 1px solid var(--medzy-color-salt);
  margin-top: 4px;
  left: 4px;
}

.ui.toggle.checkbox input:checked ~ label:after {
  box-shadow: none;
  background: #fff;
  border: 1px solid #fff;
  left: 28px;
}
