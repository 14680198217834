/*******************************
         Site Overrides
*******************************/

.ui.menu {
  font-size: 1.2em;
}

.ui.pointing.secondary.menu .item {
  font-size: 0.8em;
}
