/*******************************
         Site Overrides
*******************************/

.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 75px;
  z-index: 3;
}

.ui.very.basic.very.compact.table tbody tr,
.ui.very.basic.very.compact.table tbody tr td {
  border: none !important;
}

.ui.table[class*="middle aligned"] td,
.ui.table [class*="middle aligned"] td {
  vertical-align: middle;
}
