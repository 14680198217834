/*******************************
         Site Overrides
*******************************/

.ui.dividing.header {
  display: flex;
  align-items: center;
  padding-bottom: 0;
  border-bottom: 0;
  margin: 20px 0 10px;

  &::after {
    content: '';
    flex: 1 1 auto;
    width: 25%;
    height: 1px;
    margin-left: 15px;
    background-color: #DFE6EF;
  }
}
