/*******************************
        User Overrides
*******************************/

.ui.popup {
  margin-top: 100px;
  box-shadow: 0px 1px 2px -1px rgba(11, 40, 82, 0.66);
}

@media only screen and (max-width: 767px){
  .ui.wide.popup, .ui[class*="very wide"].popup {
    max-width: 325px;
  }
}
