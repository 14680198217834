/*******************************
        User Overrides
*******************************/

.ui.inverted.dimmer > .content > * {
  color: #96A2B2;
}

.blurring.dimmed.dimmable > .content {
  opacity: 0.5;
}
